<template>
  <div class="change-password-container">
    <loading :can-cancel="true"></loading>
    <PanelHeader title="تغییر رمز عبور" />
    <div class="change-password-wrapper">
      <div class="change-password-form">
        <div class="informations">
          <p>
            در صورت لزوم می‌توانید از طریق این صفحه کلمه عبور خود را تغییر دهید.
          </p>
          <p>
            سعی کنید کلمه عبور پیچیده ای انتخاب کنید و با سایر حساب های شما در
            سایت های دیگر یکسان نباشد.
          </p>
          <p>
            به منظور افزایش سطح ایمنی حساب کاربری خود می توانید احراز هویت دو
            مرحله ای را نیز فعال کنید.
          </p>
        </div>
        <div class="form-group">
          <GrayInput
            label="رمز عبور فعلی"
            type="password"
            required="true"
            min-length="6"
            ref="oldPassword"
            v-model="old_password"
          />
          <GrayInput
            label="رمز عبور جدید"
            type="password"
            required="true"
            min-length="6"
            ref="newPassword"
            v-model="new_password"
          />
          <GrayInput
            label="تکرار رمز عبور"
            type="password"
            required="true"
            min-length="6"
            ref="passwordConfirmation"
            v-model="password_confirmation"
          />
        </div>
        <BlueBtn class="changePasswordBtn" :onClick="changePassword" />
      </div>
      <div class="image">
        <img src="../assets/images/Security-pana.svg" alt="" width="100%" />
      </div>
    </div>
  </div>
</template>

<script>
import PanelHeader from "@/components/PanelHeader";
import GrayInput from "@/components/input/GrayInput";
import BlueBtn from "@/components/Button/BlueBtn";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";

export default {
  components: { BlueBtn, GrayInput, PanelHeader, Loading },
  data() {
    return {
      old_password: "",
      new_password: "",
      password_confirmation: ""
    };
  },
  methods: {
    changePassword() {
      this.$refs.oldPassword.validateForm();
      if (this.$refs.oldPassword.errorMassage) {
        this.$refs.oldPassword.focus();
        return false;
      }

      this.$refs.newPassword.validateForm();
      if (this.$refs.newPassword.errorMassage) {
        this.$refs.newPassword.focus();
        return false;
      }

      this.$refs.passwordConfirmation.validateForm();
      if (this.$refs.passwordConfirmation.errorMassage) {
        this.$refs.passwordConfirmation.focus();
        return false;
      }

      if (this.new_password !== this.password_confirmation) {
        this.$toast.error("رمز عبور با تکرار آن مطابقت ندارد");
        return false;
      }

      let data = {
        old_password: this.old_password,
        password: this.new_password,
        password_confirmation: this.password_confirmation
      };

      let loader = this.$loading.show({
        loader: "bars",
        color: "#080F66"
      });
      axios({
        url: "/panel/account/change-password",
        data: data,
        method: "POST"
      })
        .then(response => {
          if (!response.data.status) {
            loader.hide();
            this.$toast.error(response.data.message);
            return false;
          }
          loader.hide();
          this.$toast.success(response.data.message);
        })
        .catch(error => {
          console.log(error.response.data);
        });
    }
  }
};
</script>

<style scoped>
.change-password-container {
  /*width: calc(100% - 250px);*/
  /*margin-right: auto;*/
  /*padding: 0 40px;*/
  width: 100%;
}

.change-password-wrapper {
  padding: 0 10px;
  /*height: 100vh ;*/
}

.change-password-form {
  margin: 20px auto;
  max-width: 1109px;
  min-height: 235px;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 30%);
  border-radius: 10px;
  padding: 20px 30px;
  height: fit-content;
  width: 100%;
}

.form-group {
  display: flex;
  gap: 40px;
  justify-content: center;
  margin-top: 23px;
  flex-wrap: wrap;
}

p {
  font-size: 14px;
  font-weight: 400;
}

.changePasswordBtn {
  margin: 25px auto;
}

.image {
  width: 100%;
  /*padding: 0 40px;*/
}

img {
  float: left;
}

.footer {
  width: 100%;
}

@media (min-width: 992px) {
  .change-password-container {
    width: calc(100% - 250px);
    margin-right: auto;
  }

  .form-group {
    flex-wrap: wrap;
  }

  .change-password-wrapper {
    padding: 0 40px;
  }

  .image {
    width: 40%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .change-password-container {
    width: calc(100% - 250px);
    margin-right: auto;
  }
}
</style>
